<template>
  <b-container class="p-5">
    <h1 class="h2 mb-4">Artikel</h1>
    <b-row class="mb-4">
      <b-col
        v-for="(post, index) in posts"
        :key="`article-${index}`"
        sm="4"
        class="mb-4"
      >
        <article-card
          :id="post.ID"
          :title="post.title"
          :summary="post.excerpt"
        />
      </b-col>
    </b-row>
    <b-row class="text-md-center" v-if="loading == true">
      <b-col lg="12">
        <b-icon icon="three-dots" animation="cylon" font-scale="4"></b-icon>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import axios from 'axios'
import ArticleCard from '../components/ArticleCard.vue'
export default {
  name: 'Articles',
  components: {
    ArticleCard,
  },

  data() {
    return {
      cerdasKeuanganUrl: this.getApiURL('cerdaskeuangan'),
      bundaCerdasKeuanganUrl: this.getApiURL('bundacerdaskeuangan'),
      posts: [],
      page: 1,
      totalPosts: 0,
      loading: true,
    }
  },

  mounted() {
    this.loadPosts()
    window.addEventListener('scroll', this.handleScroll, {
      passive: true,
    })
  },
  methods: {
    getApiURL(subdomain) {
      return `https://public-api.wordpress.com/rest/v1.1/sites/${subdomain}.wordpress.com/posts/`
    },
    loadPosts() {
      axios
        .all([
          axios.get(this.bundaCerdasKeuanganUrl, {
            params: {
              number: 9,
              page: this.page,
              offset: 0,
              order_by: 'date',
              order: 'DESC',
            },
          }),
          axios.get(this.cerdasKeuanganUrl, {
            params: {
              number: 9,
              page: this.page,
              offset: 0,
              order_by: 'date',
              order: 'DESC',
            },
          }),
        ])
        .then(
          axios.spread((firstResponse, secondResponse) => {
            this.posts = [
              ...this.posts,
              ...firstResponse.data.posts,
              ...secondResponse.data.posts,
            ]

            this.totalPosts = secondResponse.data.found
            this.loading = false
          })
        )
        .catch((error) => {
          this.error = error
          this.loading = false
        })
    },
    handleScroll() {
      const { scrollTop, scrollHeight, clientHeight } = document.documentElement

      if (
        scrollTop + clientHeight >= scrollHeight - 1000 &&
        !this.loading &&
        this.posts.length < this.totalPosts
      ) {
        this.loading = true
        this.page++
        this.loadPosts()
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
}
</script>
