<template>
  <b-card
    :title="title"
    img-top
    tag="article"
    class="shadow-sm"
    border-variant="light"
  >
    <b-card-text v-html="summary.slice(0, 300) + '..'"></b-card-text>
    <b-link
      style="color: #dc3545; text-decoration: none"
      :to="`/article/${id}`"
      class="card-link"
      >Selengkapnya</b-link
    >
  </b-card>
</template>

<script>
export default {
  name: 'ArticleCard',
  props: {
    id: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    summary: {
      type: String,
      required: true,
    },
  },
}
</script>
